import React, { useState } from "react"
import H2 from "../../../core/H2"
import P from "../../../core/P"
import Twitter from "../../../../assets/images/store/Twitter Default.svg"
import LinkedIn from "../../../../assets/images/store/LinkedIn Default.svg"
import GitexPopupModal from "../../../core/GitexPopupModal"
import LinkedInhover from "../../../../assets/images/store/LinkedIn On Hover.svg"
import Twitterhover from "../../../../assets/images/store/Twitter On Hover.svg"
const CESSingleProfile = () => {
  const [openGitex, setOpenGitex] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isTwitterHovered, setIsTwitterHovered] = useState(false)

  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/meet-us-at-ces-2025"}
        />
      )}
      <div
        //className="flex mx-auto lg:max-w-screen-xl md:max-w-screen-sm max-w-screen-sm justify-center items-center bg-white rounded-md"
        className="px-4 sm:px-6 w-screen lg:px-12 bg-blue-200 py-4 sm:py-6 lg:py-12"
        data-aos="fade-up"
      >
        <div className="grid grid-cols-6 lg:gap-6 md:gap-12 gap-6 lg:py-6 md:py-10 py-12">
          <div className="lg:col-span-4 md:col-span-6 col-span-6 p-4">
            {/*  add event organizer name and description */}
            <div>
              <div className="font-serif font-bold lg:text-5xl text-3xl  tracking-normal lg:text-left md:text-center text-center text-shark-500">
                Meet WebMobTech's Innovation Leader
              </div>
              <br />
              <div className="lg:text-left md:text-center lg:text-xl md:text-xl text-lg text-center font-medium text-shark-500">
                <b>Mr. Pramesh Jain</b> (Founder & CEO) will be WebMob
                Technologies professional representative for the event.
              </div>
              <br />
              <div className="lg:text-left md:text-center lg:text-xl md:text-xl text-lg text-center font-medium text-shark-500">
                He believes in <b>“Think Bigger, Make Better!”</b>, which is
                also the motto of our company. In 2010, with nothing more than
                ambition and a small room, Pramesh Jain laid the foundation for
                what is now WebMobTech. Guided by relentless passion and an
                entrepreneurial spirit, he built the company from the ground up,
                fostering a culture of innovation and collaboration. Meet him at
                CES 2025 to explore how bold ideas and smart technologies can
                shape the future of business.
              </div>
              <div className="mt-8  flex justify-center lg:justify-start">
                <button
                  className="bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                  onClick={openGitexModal} // remove after gitex2022
                >
                  Schedule a Meeting
                </button>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 md:col-span-6 col-span-6 flex flex-wrap content-center">
            <div className="flex flex-wrap content-center mx-auto relative">
              <div>
                <div className="self-center lg:w-52 lg:h-52 md:w-52 md:h-52 w-40 h-40">
                  {/*  add event organizer image*/}
                  <img
                    src={
                      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/PrameshSir.png"
                    }
                    alt={"Pramesh Jain"}
                    width="180"
                    className="w-60 rounded-full"
                    height="180"
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-col justify-center items-center pt-8">
                  <div className="flex justify-center items-center text-shark-500">
                    <h2 className="text-2xl font-bold">Pramesh Jain</h2>
                  </div>
                  <div className="flex justify-center items-center mt-2">
                    <p className="text-lg font-medium text-shark-500">
                      Founder & CEO
                    </p>
                  </div>
                  <div className="flex justify-center items-center mt-4">
                    {/* LinkedIn Link */}
                    <a
                      href="https://www.linkedin.com/in/prameshjain/"
                      className="py-2 inline-block text-white"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <img
                        src={isHovered ? LinkedInhover : LinkedIn}
                        alt="LinkedIn"
                        className="h-8"
                        loading="lazy"
                        width="32px"
                        height="32px"
                      />
                      <span className="sr-only">LinkedIn</span>
                    </a>
                    <a
                      href="https://twitter.com/webmobtech"
                      className="py-2 inline-block text-white ml-5 "
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setIsTwitterHovered(true)}
                      onMouseLeave={() => setIsTwitterHovered(false)}
                    >
                      <img
                        src={isTwitterHovered ? Twitterhover : Twitter}
                        alt="Twitter"
                        className="h-8"
                        loading="lazy"
                        width="32px"
                        height="32px"
                      />
                      <span className="sr-only">Twitter</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CESSingleProfile
